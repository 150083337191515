import Notification from '../Notification';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';

export default function Unauthorized() {

    const theme = createTheme({
        palette: {
            primary: {
                main: '#878c33'
            },
            secondary: {
                main: "#122b3b"
            }
        }
    });

    return (


        <>
            <Notification
                PageID={1} />
            <div className="Main">
                <ThemeProvider theme={theme}>
                    {< Container component="main" maxWidth="sm" className="loginPanel" >
                        <CssBaseline />
                        <Paper
                            elevation={6}
                            sx={{
                                marginTop: 25,
                                padding: 10,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <div className='LoginHeader'>
                                <h2>UnAuthorized</h2>
                            </div>

                        </Paper>
                    </Container>}

                </ThemeProvider>
            </div >
        </>
    )

}